import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../services/ServerService";
import * as moment from "moment";
let diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];


@Injectable({
  providedIn: 'root'
})
export class DisponibilidadeService extends  ServerService {
  constructor(public http: HttpClient) {
    super(http)
  }

  salveDisponibilidade(disponibilidade: any, catalogo: any): Promise<any> {
    let dados = {
      cid: catalogo.id,
      disponibilidade: disponibilidade
    }
    return this.salve('/disponibilidades', dados);
  }


  obtenhaDisponibidadesComDescricao(disponibilidades) {
    if(!disponibilidades) return []

    disponibilidades.forEach((disp: any) =>   this.obtenhaDescricao(disp))

    return disponibilidades;
  }

  obtenhaDescricao(disponibilidade: any){
    let descricao  = '';
    if(disponibilidade.dataInicio || disponibilidade.dataFim ){
      let inicio =  disponibilidade.dataInicio ? moment(disponibilidade.dataInicio).format('DD/MM/YYYY') : null;
      let fim =  disponibilidade.dataFim ? moment(disponibilidade.dataFim).format('DD/MM/YYYY') : null;

      if(!fim){
        disponibilidade.descricaoInicioFim =  String(`a partir de ${inicio}`)
      }  else if (!inicio){
        disponibilidade.descricaoInicioFim =  String(`até ${fim}`)
      }  else {
        if(inicio === fim)
          disponibilidade.descricaoInicioFim =  String(`${inicio}`)
        else {
          disponibilidade.descricaoInicioFim =  String(` ${inicio} á ${fim}`)
        }
      }
      descricao = String(`(${ disponibilidade.descricaoInicioFim}) `)
    }

    for(let i = 0; i < disponibilidade.periodos.length; i++){
      let periodo: any = disponibilidade.periodos[i];

      let descricaoPeriodo: string = this.getDescricaoDias( periodo.dias.map((item: any) => item.dia) );
      // periodo.dias.map((item: any) => diasSemana[item.dia]).join(',');

      if( i === 0)
        descricao +=  descricaoPeriodo;
      else
        descricao += ' e ' + descricaoPeriodo;

      periodo.diasDescricao  = String(`${descricaoPeriodo}`)

      if(periodo.horaInicio && periodo.horaFim){
        let inicio =  periodo.horaInicio,
          fim = periodo.horaFim;

        if(inicio instanceof Date){
          inicio = inicio.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
          fim = fim.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
        }

        let turnoDescricao =
          String(` - ${inicio.substr(0, 5)} ás ${fim.substr(0, 5)}`);

        descricao += turnoDescricao
        periodo.turnoDescricao = turnoDescricao;
      }
    }

    disponibilidade.descricao = descricao.trim();

  }

  getDescricaoDias(dias) {
    let diasDescricao = '';

    // Verifica se os dias formam uma sequência de três dias consecutivos
    if (dias.length === 3 && dias[2] - dias[0] === 2 && dias.includes(dias[0] + 1)) {
      diasDescricao = diasSemana[dias[0]] + ' a ' + diasSemana[dias[2]];
    } else {
      // Verifica se os dias formam uma sequência contínua
      if (dias.length > 1) {
        let sequencia = true;

        for (let i = 0; i < dias.length - 1; i++) {
          if (dias[i] + 1 !== dias[i + 1]) {
            sequencia = false;
            break;
          }
        }

        if (sequencia) {
          diasDescricao = diasSemana[dias[0]] + ' a ' + diasSemana[dias[dias.length - 1]];
        }
      }

      // Se não formam uma sequência contínua ou de três dias consecutivos, retorna a descrição dos dias individualmente
      if (diasDescricao === '') {
        diasDescricao = dias.map((dia) => {
          return diasSemana[dia];
        }).join(', ');
      }
    }

    return diasDescricao;
  }


}
