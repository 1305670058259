<div class="page-title-right">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item"><a routerLink="/admin/temas">Temas</a></li>
    <li class="breadcrumb-item active">Criar Tema Personalizado</li>
  </ol>
</div>

<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded mr-1" (click)="voltar()"><i class="fas fa-chevron-left"></i></button>


  <i class="fas fa-paint-roller"></i> Criar Tema Personalizado</h4>

<form [formGroup]="temaForm" (ngSubmit)="salvarTema()">
  <div class="card" style="max-width: 800px;">
    <div class="card-body">
      <div class="form-group">
        <label for="nome" class="mr-1">Nome do Tema: </label>
        <input type="text" class="form-control" id="nome" formControlName="nome" required>
      </div>

      <div class="form-group">
        <label for="corFundoDoSite" class="mr-1">Cor de Fundo do Site: </label>
        <kendo-colorpicker name="corFundoDoSite" formControlName="corFundoDoSite"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta é a cor que será usada no fundo do site.
        </small>
      </div>

      <div class="form-group">
        <label for="corFundo" class="mr-1">Cor de Fundo Topo e Rodapé: </label>
        <kendo-colorpicker formControlName="corFundo"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta é a cor que será usada no topo e rodapé do site.
        </small>
      </div>

      <div class="form-group">
        <label for="corTextoFundo" class="mr-1">Cor do Texto: </label>
        <kendo-colorpicker formControlName="corTextoFundo"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta é a cor do texto que será usada no topo e rodapé do site.
        </small>
      </div>

      <div class="form-group">
        <label for="corBotao" class="mr-1">Cor dos Botões do Site: </label>
        <kendo-colorpicker formControlName="corBotao"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta cor será aplicada a todos os botões do site.
        </small>
      </div>

      <div class="form-group">
        <label for="corTextoPrimaria" class="mr-1">Cor do Texto Primária: </label>
        <kendo-colorpicker formControlName="corTextoPrimaria"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta é a cor principal do texto no site.
        </small>
      </div>

      <div class="form-group">
        <label for="corTextoSecundaria" class="mr-1">Cor do Texto Secundária: </label>
        <kendo-colorpicker formControlName="corTextoSecundaria"></kendo-colorpicker>
        <small class="form-text text-muted">
          Esta é a cor secundária do texto no site. Exemplo: Descrição dos produtos.
        </small>
      </div>

      <div class="form-group">
        <label for="corTextoBotao" class="mr-1">Cor do Texto do Botão: </label>
        <kendo-colorpicker formControlName="corTextoBotao"></kendo-colorpicker>
      </div>

      <div class="form-group">
        <label for="corPrecoAdicional" class="mr-1">Cor Tags de Preço Adicional: </label>
        <kendo-colorpicker formControlName="corPrecoAdicional"></kendo-colorpicker>
      <small class="form-text text-muted">
        Esta cor é usada nos preços dos adicionais dos produtos.
      </small>
      </div>

      <div class="form-group mt-4">
        <label>Visualização do Tema:</label>
        <div class="d-flex justify-content-start" style="height: 480px">
          <iframe id="mobile-content" src="https://fibo.meucardapio.ai" width="375px" height="667px" style="border: 1px solid #ddd; border-radius: 4px; transform: scale(0.75); transform-origin: 0 0;" sandbox="allow-scripts allow-same-origin"></iframe>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button type="submit" class="btn btn-primary" [disabled]="!temaForm.valid || salvando">
        <i class="fas fa-save mr-2"></i>Salvar Tema
      </button>
      <button type="button" class="btn btn-secondary ml-2" (click)="cancelar()">
        <i class="fas fa-times mr-2"></i>Cancelar
      </button>
    </div>
  </div>
</form>

<div *ngIf="mensagemSucesso" class="alert alert-success mt-3">
  {{ mensagemSucesso }}
</div>

<div *ngIf="mensagemErro" class="alert alert-danger mt-3">
  {{ mensagemErro }}
</div>
