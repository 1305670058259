<div style="position: relative;">
  <div class="row">
    <div class="col" style="width: 400px;flex: none;" *ngIf="false">
      <div class="card">
        <div class="card-body">
          <kendo-dropdownlist [data]="cenarios" [(ngModel)]="cenarioEscolhido" textField="nome"
          style="width: 100%;"></kendo-dropdownlist>
          <div class="mt-1"> </div>
          <button class="btn btn-primary" (click)="executarTeste()">
          <i class="fa fa-play"></i> Executar
          </button>
        </div>
      </div>
    </div>

    <div class="col">
      <kendo-chat
        style="max-width: 100%;margin: 0px;" [style.height.px]="altura"
        [messages]="feed | async"
        [user]="user"
        (sendMessage)="sendMessage($event)"
      >
        <ng-template kendoChatAttachmentTemplate let-attachment>
        </ng-template>
        <ng-template kendoChatMessageTemplate let-message let-i="index">
          <div *ngIf="message.attachments && message.attachments.length > 0">
            <ng-container *ngFor="let anexo of message.attachments">
              <div class="k-card" *ngIf="anexo.contentType === 'image/png'">
                <img [src]="anexo.content" style="width: 350px;"/>
              </div>
              <div class="k-card" *ngIf="anexo.contentType === 'application/pdf'">
                <iframe [src]="anexo.content" alt="foto" width="450px" height="350px" frameborder="0"></iframe>
              </div>
            </ng-container>
          </div>
          <div [innerHTML]="message.text" style="max-width: 350px;font-size: 13px;line-height: 15px;">
          </div>
        </ng-template>
      </kendo-chat>
      <button class="btn btn-primary" (click)="resetConversa()" style="position: absolute;bottom: 57px;right: 0px;" kendoTooltip title="Reseta conversa e ativa a mensagem de saudação">Reiniciar Conversa</button>
      <div class="fundo-chat2"></div>
      <div class="fundo-chat"></div>
    </div>
  </div>
</div>

