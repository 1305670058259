import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UsuarioService} from "../services/usuario.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ContatosService} from "../services/contatos.service";
import {CampanhaService} from "../services/campanha.service";
import {Observable} from "rxjs";
import {AutorizacaoService} from "../services/autorizacao.service";
import * as async from "async";
import {EnumMeioDeEnvio} from "../../../server/domain/EnumMeioDeEnvio";
import {FileRestrictions, SuccessEvent, UploadComponent} from "@progress/kendo-angular-upload";
import {ErrorCallback} from "async";
import {CadPausasProgramadasComponent} from "../compartilhado/cad-pausas-programadas/cad-pausas-programadas.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {TelaEscolherCupomCampanhaComponent} from "../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {TelaEscolherDominioEmpresaComponent} from "../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {NgForm} from "@angular/forms";
import {
  UploadContatosCampanhaComponent
} from "../marketing/upload-contatos-campanha/upload-contatos-campanha.component";
import {MiaService} from "../services/mia.service";
import { TabStripComponent } from '@progress/kendo-angular-layout';
import {ControleTextoMensagemComponent} from "../controle-texto-mensagem/controle-texto-mensagem.component";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FiltroTelaUI} from "../../../projects/loja/src/objeto/FiltroTelaUI";


declare let moment;

@Component({
  selector: 'app-nova-campanha',
  templateUrl: './nova-campanha.component.html',
  styleUrls: ['./nova-campanha.component.scss']
})
export class NovaCampanhaComponent implements OnInit, AfterViewInit {
  @ViewChild('frmMensagem', {static: false})
  frmMensagem: NgForm;
  @ViewChild('frmContatos', {static: false})
  frmContatos: NgForm;

  @Output() onVoltar = new EventEmitter();
  @ViewChild('tabSet', {static: true}) tabSet: TabStripComponent;
  @ViewChild('uploadComponent') uploadComponent: UploadComponent;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ia = {
    usarCupomDeDesconto: false,
    cupom: undefined,
    temValorMinimo: undefined,
    valorMinimo: undefined,
    novaIdeia: undefined
  };
  saldoIA = 0;
  miaAtivada = false;
  agora = new Date();
  resumo = null;
  contatos: Array<any> = [];
  contatosBusca: Array<any> = [];
  contato: any;
  paginacao: any = {totalPorPagina: 5, pagina: 1, total: 0 };
  EXPRESSAO_URL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;
  filtro: any = {};
  carregandoContatos = false;
  public steps: any = { hour: 0, minute: 15 };
  tooltipCampanhaEnviando = 'A lista de contatos de campanhas já enviadas não pode ser editada, mas o nome e a mensagem da campanha podem ser alterados';

  statusPodeEditar = ['Nova', 'Enviando', 'ParouEnvio']
  _statusIndicamEnviou = ['Enviando', 'ParouEnvio'];
  campanhaJaFoiEnviada = false;
  campanha = {
    id: null,
    nome: '',
    mensagem: '',
    status: 'Nova',
    filtro: null,
    linkImagem: '',
    horarioEnvio: moment().add('1', 'hour').set({m: 0}).toDate(),
    tipoDeEnvio: 'Unico',
    qtdeDeDiasNovaNotificacao: 1,
    origemContatos: 'Filtro',
    files: [],
    contatos: [],
    inserirLinkDesinscrever: true,
    limitarQtdeContatos: false,
    qtdeContatosEnviarMsg: 250,
    naoEnviarMsgParaQuemRecebeuRecente: true,
    qtdeDiasUltimaNotificacao: 7,
    temMenu: false,
    menu: {
      opcoes: []
    }
  };
  filtros: any  = [];

  mensagemSucesso: string;
  salvando: boolean;
  mensagemErro: any;
  private state$: Observable<object>;
  empresa: any;
  carregouTela = false;
  carregandoTela = false;


  @ViewChild('uploadContatos',  {static: false} ) uploadContatos: UploadContatosCampanhaComponent;

  @ViewChild('ctlTextoMensagem', {static: false}) ctlTextoMensagem: ControleTextoMensagemComponent;

  private arquivo: File;
  private conteudoArquivo: any;
  private colunasImportar: any = [];
  private removeuPrimeiraLinha: boolean;
  separadorDecimal: any;
  opcoesSeparadorDecimal: any = [
    {codigo: ',', descricao: "Separado por ','"},
    {codigo: '.', descricao: "Separado por '.'"}
  ];
  confirmarProximoPasso: boolean;
  sugestao = '';
  terminouSugestao: boolean;
  gerandoSugestao: boolean;
  ultimaResposta: any;
  prompts = [];

  constructor(public activatedRoute: ActivatedRoute, private  contatoService: ContatosService, private router: Router,
              private usuarioService: UsuarioService, private campanhaService: CampanhaService,
              private autorizacaoService: AutorizacaoService, private dialogService: DialogService) {

  }

  ngAfterViewInit(): void {
    this.carregouTela = false;
    this.carregandoTela = true;
    this.empresa = this.autorizacaoService.getUser().empresa;

    this.campanhaService.obtenhaSaldoIA().then((resposta: any) => {
      this.saldoIA = resposta.qtde;
    });

    this.usuarioService.listeFiltros().then( filtros => {
      if(filtros.length > 0)
        this.filtros = filtros

      this.activatedRoute.params.subscribe(params => {
        let id = +params['idc']; // (+) converts string 'id' to a number
        if(id){
          this.campanhaService.obtenhaCampanha(id).then((resposta: any) => {
            this.setCampanha(resposta);
            this.carregouTela = true;
            this.carregandoTela = false;
          });
        } else {
          this.carregandoTela = false;
        }
      });
    });
  }

  ngOnInit() {

  }

  setCampanha(campanha: any) {
    this.campanha = campanha;
    this.contatos = campanha.contatos;

    if( this.campanha.origemContatos === 'Arquivo' ) {
      setTimeout( () => {
        if( this.uploadContatos ) {
          this.uploadContatos.ajusteContatos(campanha.contatos);
        }
      }, 100);
    }

    if(this.campanha.horarioEnvio)
      this.campanha.horarioEnvio = new Date(this.campanha.horarioEnvio);

    this.ctlTextoMensagem.alterouMensagem(this.campanha);

    this.filtreContatos();

    this.campanhaJaFoiEnviada = this._statusIndicamEnviou.indexOf(this.campanha.status) !== -1;
  }

  voltar() {
    this.router.navigateByUrl('/admin/marketing').then( () => {});
  }

  filtreContatos() {
    /*
    if(!this.filtro || this.campanha.origemContatos !== 'Filtro') {
      this.filtro = {}
      return;
    }

     */

    this.contatos = [];
    this.carregandoContatos = true;
    this.busqueContatos(0 , () => {
      let params = this.getParamsFiltro();
      this.contatoService.obtenhaResumo(params).then( resumo => {
        this.resumo = resumo;
        this.paginacao.total = this.resumo.total;
        this.carregandoContatos = false;
      });
    })
  }

  busqueContatos(inicio, cb: Function) {
    let params = this.getParamsFiltro();
    if( this.campanha && this.campanha.id ) {
      params.idc = this.campanha.id;
    }

    this.contatoService.listeContatos(inicio, this.paginacao.totalPorPagina, params).then( contatos => {
      this.contatosBusca = contatos;
      this.contatos = contatos;

      for( let i = 0; i < this.contatos.length; i++ ) {
        let contato = this.contatos[i];

        if(contato.mensagemProcessada){
          setTimeout( async () => {
            const urls = contato.mensagemProcessada.match(this.EXPRESSAO_URL);

            async.eachSeries(urls,
              (url, cb2: ErrorCallback<Error>) => {
                // tslint:disable-next-line:max-line-length
                contato.mensagemProcessada =
                  contato.mensagemProcessada.replace(url, "<a href='" + url + "' target='_blank'>" + url + "</a>");
                cb2();
              });
          });
        }
      }

      cb();
    })
  }

  getParamsFiltro() {
    let params: any = {};
    if( this.campanha.filtro ) {
      params = FiltroTelaUI.getParamsRequest(this.campanha.filtro.dados);
    }

    params.origemContatos = this.campanha.origemContatos;
    params.qtdeDiasUltimaNotificacao = this.campanha.qtdeDiasUltimaNotificacao;
    params.naoEnviarMsgParaQuemRecebeuRecente = this.campanha.naoEnviarMsgParaQuemRecebeuRecente;

    return params;
  }

  concluiuPasso1() {
    this.tabSet.selectTab(2);
  }





  enviarMensagens() {
    if( !this.campanha.id ) {
      this.campanhaService.salveCampanha(this.campanha).then(resp => {
        this.campanha.id = resp.id;
        this.mensagemSucesso = 'Campanha criada com sucesso criado!';
        this.salvando = false;
        this.router.navigateByUrl('/admin/tela-enviar-mensagens/' + this.campanha.id).then( () => {});
      }).catch(erro => {
        this.salvando = false;
        this.mensagemErro = erro;
      });
    } else {
      this.router.navigateByUrl('/admin/tela-enviar-mensagens/' + this.campanha.id).then( () => {});
    }
  }

  apenasSalvar() {
    if( !this.frmContatos.valid ) {
      return;
    }

    delete this.campanha.contatos;

    this.campanhaService.salveCampanha(this.campanha).then( resp =>   {
      this.campanha.id = resp.id;
      this.campanha.contatos = this.contatos;
      this.mensagemSucesso = 'Campanha criada com sucesso criado!';
      this.salvando = false;
      this.router.navigateByUrl('/admin/marketing').then( () => {});
    }).catch(erro => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  desativarCampanha() {
    delete this.campanha.contatos;
    this.campanhaService.desative(this.campanha).then( resp =>   {
      this.campanha.id = resp.id;
      this.campanha.contatos = this.contatos;
      this.mensagemSucesso = 'Campanha desativada com sucesso criado!';
      this.salvando = false;
      this.router.navigateByUrl('/admin/marketing').then( () => {});
    }).catch(erro => {
      this.salvando = false;
      this.mensagemErro = erro;
    });
  }

  getCartao(contato: any) {
    if(!this.campanha.filtro) return;

    let cartao    =  contato.cartoes[0],
        plano = this.campanha.filtro.plano;

    if(plano){
      contato.cartoes.forEach( (ct) => {
        if(ct.plano.id === plano.value)
          cartao = ct;
      })
    }

    return cartao;
  }

  temFiltro(){
    if( !this.filtro || !this.filtro.dados ) {
      return [];
    }

    return  Object.keys(this.filtro.dados).length > 0;
  }

  mudouOrigemContatos() {
    this.resumo = null;
    this.contatos = [];
    if( this.campanha.origemContatos === 'Filtro' && this.campanha.filtro ) {
      this.filtreContatos();
    }
  }

  mudouQtdeMensagensAEnviarTodos() {

  }

  mudouQtdeMensagensAEnviar() {
    this.filtreContatos();
  }

  fecharDialogAlertas() {
    this.confirmarProximoPasso = false
  }

  confirmouContinuarCampanha() {
    this.tabSet.selectTab(2);
    this.fecharDialogAlertas()
  }

  usuarioFezUploadContatos(contatos: Array<any>) {
    this.contatos = contatos;
  }

  ativarMia() {
    this.miaAtivada = true;
  }

  fecharMia() {
    this.miaAtivada = false;
  }

  async gerarSugestao() {
    this.terminouSugestao = false;
    this.gerandoSugestao = true;
    this.sugestao = '';
    //this.ctlTextoMensagem.atualizeAltura();
    let url = '/chatgpt/index';
    let operador = '?';

    if( this.ia.novaIdeia ) {
      url += operador + 'novaIdeia=' + encodeURIComponent(this.ia.novaIdeia);
      operador = '&';
    }

    this.prompts.push({
      prompt: this.ia.novaIdeia ? this.ia.novaIdeia : '',
      sugestao: ''
    });

    this.ia.novaIdeia = '';
    if( this.ultimaResposta ) {
      //url += operador + 'conversationId=' + this.ultimaResposta.conversationId + '&id=' + this.ultimaResposta.id;
      url += operador + 'um=' + encodeURIComponent(this.ultimaResposta.text);
      let promptsAnteriores = this.prompts.slice(0, this.prompts.length - 1).map( p => p.prompt ).join(' ');
      url += '&p=' + encodeURIComponent(promptsAnteriores);
      operador = '&';
    }

    const response = await fetch(url);

    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    await this.leia(reader);
  }

  async leia(reader) {
    const {value, done} = await reader.read();
    if (done) {
      this.terminouSugestao = true;
      this.gerandoSugestao = false;
      this.saldoIA --;
      return;
    }

    let linhas = value.split('<FIM>');

    let jsonMensagem = linhas[0];
    let objMensagem = JSON.parse(jsonMensagem);
    let textoMensagem = objMensagem.text;

    console.log(objMensagem);

    this.ultimaResposta = objMensagem;

    const prompt = this.prompts[this.prompts.length - 1];

    textoMensagem = textoMensagem.trimStart();
    textoMensagem = this.removaAspas(textoMensagem);
    prompt.original = textoMensagem;
    prompt.sugestao = this.formatWhatsAppText(textoMensagem)
      .replace(/\n/g, '<br>');

    setTimeout(async () => {
      await this.leia(reader);
    }, 100);
  }


  formatWhatsAppText(texto: string) {
    // substituir dois asteriscos por um asterisco e uma tag <strong>
    texto = texto.replace(/_([^_]*?)_/g, "<em>$1</em>");
    texto = texto.replace(/\*\*([^\*\*]*?)\*\*/g, "*<strong>$1</strong>*");
    // Substitui o texto entre sublinhados pela tag <i>
    texto = texto.replace(/\B\*([^<\*\s][^<\*]*[^<\*\s])\*\B/g, "<strong>$1</strong>");

    // retornar o texto transformado
    return texto;
  }


  removaAspas(str: string) {
    const semAspas = str[0] === '"' && str[str.length - 1] === '"' ? str.slice(1, -1) : str;

    return semAspas;
  }

  usarEssa(prompt: any) {
    this.fecharMia();
    this.campanha.mensagem = prompt.original;

    this.ctlTextoMensagem.atualizeAltura();
  }

  mudarParaDadosBasicos() {
    //valida se o form
    if( !this.frmMensagem.valid ) {
      return;
    }

    this.tabSet.selectTab(1);
    this.passoContatosFoiSelecionado({index: 1});
  }

  passoContatosFoiSelecionado($event) {
    if( $event.index === 1 ) {
      if( this.campanha.origemContatos === 'Arquivo' ) {
        setTimeout( () => {
          if( this.uploadContatos ) {
            this.uploadContatos.ajusteContatos(this.campanha.contatos);
          }
        }, 1);
      }
    }
  }
}
