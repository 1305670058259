import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TemplatePromptsService} from "../../services/template-prompts.service";

@Component({
  selector: 'app-tela-templates-prompts',
  templateUrl: './tela-templates-prompts.component.html',
  styleUrls: ['./tela-templates-prompts.component.scss']
})
export class TelaTemplatesPromptsComponent implements OnInit, AfterViewInit {
  templatePrompt: any = {
    nome: '',
    descricao: '',
    template: '',
  };
  @ViewChild('textArea', {static: false}) textArea: ElementRef;

  id: any;

  constructor(private router: Router, private templatePromptsService: TemplatePromptsService,
              private route: ActivatedRoute) {
    // pegue o parametro id da rota angular
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
  }

  submitForm() {
    this.templatePromptsService.salveTemplatePrompt(this.templatePrompt).then( (resposta) => {
      //volte pra lista de prompts
      this.router.navigateByUrl('/admin/templates-prompts').then( () => {});
    });
  }

  voltar() {
    this.router.navigateByUrl('/admin/templates-prompts').then( () => {});
  }

  resize(txt: any) {
    txt.style.height = 'auto';
    txt.style.height = (txt.scrollHeight + 5) + 'px';
  }

  ngAfterViewInit(): void {
    if( !this.id ) {
      return;
    }

    debugger;

    this.templatePromptsService.carrgueTemplatePrompt(this.id).then( (resposta) => {
      debugger;
      this.templatePrompt = resposta;
      setTimeout( () => {
        this.resize(this.textArea.nativeElement);
      }, 1);
    });
  }
}
