import {AfterViewInit, Component, OnInit} from '@angular/core';
import {EmpresasService} from "../../../superadmin/services/empresas.service";
import {ConstantsService} from "../../ConstantsService";
import {TemaPersonalizadoService} from "../../../services/tema-personalizado.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-tela-configuracao-temas',
  templateUrl: './tela-configuracao-temas.component.html',
  styleUrls: ['./tela-configuracao-temas.component.scss']
})
export class TelaConfiguracaoTemasComponent implements OnInit {
  empresa: any;
  ativando = false;
  temasPersonalizados: any[] = [];

  constructor(
    private empresasService: EmpresasService,
    private constantsService: ConstantsService,
    private temaService: TemaPersonalizadoService, // Novo serviço para gerenciar temas
    private router: Router
  ) {}

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe((empresa: any) => {
      if (!empresa) return;

      this.empresa = empresa;
    });
    this.carregarTemasPersonalizados();
  }

  carregarTemasPersonalizados() {
    this.temaService.listeTemas().then(temas => {
      console.log('temas: ', temas);

      this.temasPersonalizados = temas;
    });
  }

  ativarTemaPersonalizado(tema: any) {
    this.ativando = true;

    this.empresasService.ativarTema(this.empresa.id, tema.id).then(() => {
      this.ativando = false;
      this.empresa.temaPersonalizado = tema;
      this.empresa.tema = 'tema-personalizado';
    }).catch((erro) => {
      this.ativando = false;
      console.error('Erro ao ativar tema:', erro);
    });
  }

  ativarTema(tema: any) {
    this.ativando = true;
    console.log('tema: ', tema);

    this.empresasService.ativarTema(this.empresa.id, tema).then(() => {
      this.empresa.temaPersonalizado = null;
      this.ativando = false;
      debugger;
    this.empresa.tema = tema;
    }).catch((erro) => {
      debugger;
      this.ativando = false;
      console.error('Erro ao ativar tema:', erro);
    });
  }

  desativarTema() {
    this.ativando = true;

    debugger;
    this.empresasService.ativarTema(false, '').then(() => {
      this.ativando = false;
      debugger;
      this.empresa.tema = null;
      this.empresa.temaPersonalizado = null;
    }).catch( (erro) => {
      this.ativando = false;
      console.error('Erro ao desativar tema:', erro);
    });
  }

  criarNovoTema() {
    // Implementar lógica para criar novo tema
  }

  editarTema(tema: any) {
    this.router.navigate(['/admin/temas/criar/', tema.id]);
  }

  criarTemaPersonalizado() {
    this.router.navigate(['/admin/temas/criar']);
  }
}
