<div *ngIf="empresa">
  <h4 class="page-title text-left">Início</h4>

  <div class="card mb-3">
    <div class="card-body pl-2 pb-1">
      <div class="row">
        <div class="col-12 col-md-4">
          <p class="mb-1"><strong>Nome:</strong> {{usuario.id}}. {{usuario.nome}}</p>
          <p class="mt-0 mb-1">{{usuario.email}}</p>
          <p class="mt-0 mb-1" style="color: green;" *ngIf="usuario.numeroWhatsapp && usuario.numeroWhatsapp.whatsapp"><i class="fab fa-whatsapp"></i> {{usuario.numeroWhatsapp.whatsapp | telefone }}</p>
        </div>
      </div>
    </div>
  </div>


  <ng-container *ngIf="iframe">
    <app-notificacoes-promokit></app-notificacoes-promokit>
  </ng-container>

  <div style="position: absolute;top: 15px;right: 15px;">
    <ng-container   *ngIf="empresa  && isMobile">
      <button href="#" target="_blank" class="btn btn-danger mr-1" (click)="fecharAgora()" [disabled]="!(empresa.estaAberta && empresa.estaRecebendoPedidos)"
              *ngIf="!(empresa.estaAberta && !empresa.estaRecebendoPedidos)">
        Fechar Loja</button>
      <button href="#" target="_blank" class="btn btn-primary mr-1" (click)="reabrir()" *ngIf="empresa.estaAberta && !empresa.estaRecebendoPedidos">
        Abrir Novamente</button>
    </ng-container>

    <button class="btn btn-blue" (click)="recarregue();"
            kendoTooltip title="Recarregar o cardápio e forçar envio das mensagens."><i class="fas fa-sync-alt"></i></button>
  </div>

  <div class="row" *ngIf="isMobile">
    <div class="col" style="padding-right: 0px;">
     <!-- <p class="mb-0  font-15" *ngIf="empresa.estaAberta && empresa.estaRecebendoPedidos">
                <span  class="text-success">
                  Loja Aberta      <i class="fas fa-check-circle ml-1 " style="font-size: 21px;"> </i>
                </span>


      </p> -->
      <p class="mb-0  font-15" *ngIf="!empresa.estaAberta || !empresa.estaRecebendoPedidos">
         <span   class="text-danger">
                 <b> Loja Fechada</b>    <i class="fas fa-ban ml-1" style="font-size: 21px;"  ></i>
                </span>

      </p>

      <p class="mb-0 mt-1 font-13" *ngIf="empresa.mensagemPausaProgramada">Pausa Programada</p>
    </div>

  </div>

  <div>
    <ng-container *ngFor="let campanhaPendente of campanhasPendentes">
      <div class="alert alert-warning" role="alert">
        <p>
          <strong>
            <i class="fas fa-lightbulb"></i> A rede criou uma nova campanha de marketing via Whatsapp.
          </strong>
        </p>
        <div>
        Nome da Campanha: <h4>
          <a href="/campanha/123">{{campanhaPendente.nome}}</a>
        </h4>
        </div>
        <p>
          Você deve aceitar ou rejeitar a campanha para que ela seja enviada. Qualquer dúvida só falar com o pessoal do suporte.
        </p>
        <div>
          <button class="btn btn-primary" (click)="cliqueAcessarCampanha(campanhaPendente)">Acessar Campanha</button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="mb-2">
    <div class="alert alert-success mt-1" role="alert" *ngIf="msgStatus">
      <i class="mdi mdi-check-all mr-2"></i>
      <span [innerHTML]="msgStatus"></span>

      <i class="far fa-times-circle font-20" (click)="fecharAlertaStatus()"
        style="cursor:pointer;position: absolute;top: 10px;right: 5px;" *ngIf="indice === total"></i>

      <div class="mt-2">
        <kendo-progressbar [value]="indice" [max]="total" style="width: 100%;border-radius: 20px;"
                           [progressCssStyle]="{background: 'green'}"></kendo-progressbar>
      </div>
    </div>
  </div>

  <app-gerenciador-envio-campanha></app-gerenciador-envio-campanha>

  <div class="col-12 mt-2 mb-2" *ngIf="msg">
    <div class="alert alert-success mb-0" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{msg}}

      <i class="fa fa-print fa-lg ml-1 cpointer" (click)="imprimaPedido(novoPedido)" *ngIf="novoPedido.codigo"></i>
    </div>
  </div>

  <kendo-dialog title="Aviso Fatura em Atraso" *ngIf="fatura &&  fatura.vencida && !exibirModal" (close)="fecheModal()"
                [minWidth]="350" [width]="700">
    <p>
       <i class="k-icon k-i-warning text-danger fa-lg"></i>
      <span class="text-danger"> <b>Atenção!</b></span> A fatura com vencimento em  <b>{{fatura.dataVencimento}}</b> venceu
      e o pagamento não foi identificado  no sistema! Evite o bloqueio efetuando o pagamento.

      <br>
      <br>
      Caso ele já tenha sido realizado,  envie o comprovante no <b> grupo de atendimento do WhatsApp</b>,
      entre em contato através do WhatsApp do seu consultor  ou aguarde liberação automática

    </p>

    <app-fatura-aberta-pagar [fatura]="fatura"></app-fatura-aberta-pagar>
  </kendo-dialog>

  <kendo-dialog title="Fatura vence hoje" *ngIf="fatura &&  fatura.venceHoje && !exibirModal" (close)="fecheModal()"
                [minWidth]="350" [width]="700">

    Informamos que o boleto com vencimento <b>{{fatura.dataVencimento}}</b>  e valor <b>{{fatura.total  | currency: "R$"}}</b>
    já se encontra disponível para pagamento e está vencendo hoje.

    <app-fatura-aberta-pagar [fatura]="fatura"></app-fatura-aberta-pagar>
  </kendo-dialog>

  <div  *ngIf="fatura && !fatura.paga && !fechouAlerta" class="row">
    <div class="col-12 ">
      <div class="card" >
        <div class="card-body">
          <button type="button" aria-label="Close" class="close" (click)="fecheAterta()"><span aria-hidden="true">×</span></button>
          <p class="mt-1">
            Sua fatura com vencimento <b>{{fatura.dataVencimentoTexto}}</b>  e valor <b>{{fatura.total  | currency: "R$"}}</b>
            já se encontra disponível para pagamento.
          </p>

          <app-fatura-aberta-pagar [fatura]="fatura"></app-fatura-aberta-pagar>
        </div>
      </div>
    </div>
  </div>


  <botoes-home #botoesHome [empresa]="empresa" [tela]="this"></botoes-home>

  <div class="row resumo mt-3"  *ngIf="exibirFinanceiro">
    <div class="col-12 col-lg-4">
      <div class="card-box com-borda text-left">
        <div class="container_icone">
          <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/novoscartoes-dashboard.svg'" [removeSVGAttributes]="['fill', 'width', 'height']" style="width: 32px;height: auto;"></div>
          <span class="ml-1"> {{resumo.total}}</span>
        </div>
        <label class="d-none d-sm-block">Novos Cartões </label>
        <label class="d-sm-none">Novos Cartões</label>
      </div>
    </div>
    <div class="col-12 col-lg-4" >
      <div class="card-box text-left">
        <div class="container_icone">
          <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/selosenviados-dashboard.svg'" [removeSVGAttributes]="['fill', 'width', 'height']" style="width: 32px;"></div>
          <span class="ml-1"> {{resumoVenda.pontos}}</span>
        </div>
        <label class="d-none d-sm-block">Pontos conquistados no período </label>
        <label class="d-sm-none">Pontos conquistados no período</label>
      </div>
    </div>
    <div class="col-12 col-lg-4"  >
      <div class="card-box text-left">
        <div class="container_icone">
          <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/receita-dashboard.svg'" [removeSVGAttributes]="['fill', 'width', 'height']" style="width: 42px;height: auto;"></div>

          <span class="ml-1"> {{resumoVenda.total | currency: 'BRL'}}</span>
        </div>
        <label class="d-none d-sm-block">Receita Fidelidade no período</label>
        <label class="d-sm-none">Receita Fidelidade  no período</label>
      </div>
    </div>
  </div>

  <div class="row  mt-3" *ngIf="resumo && resumo.total == 0">
    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-body" style="max-height: 450px;">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-6">
              <div class="row align-items-center">
                <div class="col" style="flex: 0 0 50px;">
                  <img src="/assets/fidelidade/icones/icon-user-plus.svg"/>
                </div>
                <div class="col pl-0 mt-3 pr-0">
                  <h2 style="color: #6EB923" class="mb-0">Não perca clientes</h2>
                  <p class="text-muted" style="font-size: 16px">Fidelize seu cliente através de apenas 1 número de whatsapp.</p>
                </div>
              </div>
            </div>
            <div class="col-6 mr-0 pl-0">
              <img src="/assets/fidelidade/icones/ilustration-whatsapp.png" class="img-fluid" style="max-height: 257px;"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-body" style="max-height: 450px;">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-6">
              <div class="row align-items-center">
                <div class="col" style="flex: 0 0 50px;">
                  <img src="/assets/fidelidade/icones/icon-fidelidade-pink.svg"/>
                </div>
                <div class="col pl-0 mt-3 pr-0">
                  <h1 style="color: #EE4E61" class="mb-0">Fidelização</h1>
                  <p class="text-muted" style="font-size: 16px">Crie cartões de fidelidade para seus clientes e aumente
                    em 30% sua receita.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mr-0 pl-0">
              <img src="/assets/fidelidade/icones/ilustration-selo.png" class="img-fluid" style="margin-left: 10px;max-height: 450px;"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="resumo.total > 0">
    <div class="col">
      <h3 class="mt-3 titulo_grafico">Receita de Fidelidade</h3>

      <div class="card">
        <div class="card-body grafico">
          <div class="controle-grafico">
            <div class="btn-group btn-group-toggle " data-toggle="buttons">
              <button class="btn btn-light " [ngClass]="{'active':tipoGrafico === 'dia'}"  (click)="exibaGraficoDia()">Dia</button>
              <button class="btn btn-light"  [ngClass]="{'active':tipoGrafico === 'mes'}"  (click)="exibaGraficoMes()">Mês</button>
              <button class="btn btn-light"  [ngClass]="{'active':tipoGrafico === 'ano'}"  (click)="exibaGraficoAno()">Ano</button>
            </div>
          </div>

          <div class="mt-2"></div>

          <kendo-chart  [categoryAxis]="{ categories: seriesLabels}" >
            <kendo-chart-title align="left">
            </kendo-chart-title>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="seriesLabels">

                <kendo-chart-category-axis-item-labels [rotation]="90">
                </kendo-chart-category-axis-item-labels>

              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>

            <kendo-chart-axis-defaults>
              <kendo-chart-axis-defaults-labels format="c0" [rotation]="0">
              </kendo-chart-axis-defaults-labels>
            </kendo-chart-axis-defaults>

            <kendo-chart-area  [margin]="10" > </kendo-chart-area>
            <kendo-chart-series>
              <kendo-chart-series-item [data]="series | async" [type]="'line'"
                                       [overlay]="{gradient: 'roundedBevel'}" color="#48d746">
                <kendo-chart-series-item-tooltip>
                  <ng-template let-value="value" let-category="category">
                    <p style="text-align: center;padding-bottom: 5px;width: 200px;">
                      <span style="font-size: 15px;font-weight: bold;">{{category}}</span>
                    </p>
                    <h4 style="text-align: center;color: #e3fcdc;font-weight: bold;">{{value | currency: 'BRL'}}</h4>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>

              <kendo-chart-series-item [data]="series | async" [type]="'column'"
                                       [overlay]="{gradient: 'roundedBevel'}">
                <kendo-chart-series-item-tooltip>
                  <ng-template let-value="value" let-category="category">
                    <p style="text-align: center;padding-bottom: 5px;width: 200px;">
                      <span style="font-size: 15px;font-weight: bold;">Receita {{category}}</span><br>
                    </p>
                    <h3 style="text-align: center;color: #f3f3f3;font-weight: bold;">{{value | currency: 'BRL'}}</h3>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>

        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="ultimasAcoes.length > 0">
    <div class="card-body">
      <h4 class="header-title mb-0">Últimas ações</h4>

      <div id="cardCollpase4" class="collapse pt-3 show">
        <div class="table-responsive">
          <table class="table table-centered table-borderless mb-0">
            <thead class="thead-light">
            <tr>
              <th></th>
              <th>Contato</th>
              <th>Ação</th>
              <th>Horário</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let acao of ultimasAcoes">
              <td>
                <app-icone-acao [acao]="acao"></app-icone-acao>
              </td>
              <td>
                <div *ngIf="acao.tipo == tipoDeAcaoEnum.NOVO_CONTATO" class="icone_svg mr-2" [inlineSVG]="'/assets/fidelidade/icones/novoscartoes-dashboard.svg'" [removeSVGAttributes]="['fill', 'width', 'height']"></div>
                <div *ngIf="acao.tipo == tipoDeAcaoEnum.GANHOU_PONTOS" class="icone_svg mr-2" [inlineSVG]="'/assets/fidelidade/icones/novoselo.svg'" [removeSVGAttributes]="['fill', 'width', 'height']"></div>
                <div class="icone_svg mr-2" *ngIf="acao.tipo == tipoDeAcaoEnum.TROCOU_PONTOS">
                  <div class="icone_trocar_pontos" [inlineSVG]="'/assets/fidelidade/icones/trocar-selo.svg'" [removeSVGAttributes]="['fill', 'width', 'height']"></div>
                </div>
                <a routerLink="/admin/contatos/{{acao.contato.codigoPais}}{{acao.contato.telefone}}" class="text-primary"> {{acao.contato.nome}}</a>
              </td>
              <td>
                {{acao.descricao}}
              </td>
              <td>{{acao.horario}}</td>
              <td>
                <a href="/imprimir/troca/{{acao.brinde.codigo}}"  target="_blank" *ngIf="acao.brinde">
                  <i class="fa fa-print fa-lg mr-2">  </i>
                </a>

                <a href="/imprimir/pontuacao/{{acao.pontuacao.codigo}}"  target="_blank" *ngIf="acao.pontuacao &&  acao.tipo == 2">
                  <i class="fa fa-print fa-lg mr-2">  </i>
                </a>

              </td>
            </tr>
            </tbody>
          </table>
        </div> <!-- .table-responsive -->
      </div> <!-- end collapse-->
    </div> <!-- end card-body-->
  </div>
</div>
