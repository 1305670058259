import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { TelaEscolherDominioEmpresaComponent } from '../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component';
import { TelaEscolherCupomCampanhaComponent } from '../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ConstantsService} from "../fidelidade/ConstantsService";

@Component({
  selector: 'app-controle-editor-mensagem',
  templateUrl: './controle-editor-mensagem.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ControleEditorMensagemComponent,
      multi: true
    }
  ],
  styleUrls: ['./controle-editor-mensagem.component.scss']
})
export class ControleEditorMensagemComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  _value = '';

  valor = '';

  empresa: any;

  //@ViewChild('txtNovaIdeia', { static: false }) txtNovaIdeia: ElementRef;

  qtdeCaracteresRestantes = 160;
  QTDE_MAXIMA_CARACTERES = 160;

  @Input() campos = [{
    nome: 'Empresa possui Pix',
    texto: 'temPix',
  }, {
    nome: 'Empresa possui Pix Online',
    texto: 'temPixOnline',
  }, {
    nome: 'Cliente Tem Pedido Recente',
    texto: 'temPedidoEmAberto',
  }, {
    nome: 'Cliente Tem Pedido Recente',
    texto: 'temPedidoEmAberto',
  }];

  constructor(private dialogService: DialogService, private constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) {
        return;
      }

      this.empresa = empresa;
    });
  }

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  resize(txt: any) {
    txt.style.height = 'auto';
    txt.style.height = (txt.scrollHeight) + 'px';
  }

  adicioneCampo(textArea, campo: any) {
    if( campo.texto === 'Link_Cardapio_DARK' ) {
      TelaEscolherDominioEmpresaComponent.abraComoDialog(this.dialogService, this.empresa,
        (dominioEmpresa) => {
          this.insiraCampoNaCaixaDeTexto(textArea, {
            texto: `[Link_Cardapio_${dominioEmpresa}]`
          });
        });

      return;
    }
    else if( campo.texto === 'Cupom' ) {
      TelaEscolherCupomCampanhaComponent.abraComoDialog(this.dialogService, this.empresa, (cupom) => {
        this.insiraCampoNaCaixaDeTexto(textArea, {
          texto: `[CUPOM_${cupom}]`
        });
      });

      return;
    }

    this.insiraCampoNaCaixaDeTexto(textArea, campo);
    this.alterouMensagem();
  }

  insiraCampoNaCaixaDeTexto(textArea, campo: any) {
    textArea.focus();

    const posicao = this.getCaretPos(textArea);

    this._value = this._value.substr(0, posicao) + campo.texto + this._value.substr(posicao);

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
    }, 50);
  }

  alterouMensagem() {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = this._value.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(this._value)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[Link_Cardapio]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 30;
      }
    }

    this.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - this._value.length + qtdARemover - qtdAAdicionar;
    this.value = this._value;
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }

  setCaretPosition(elem, caretPos) {
    if (elem !== null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }

  ngAfterViewInit(): void {
    //if( this.empresa.meioDeEnvio === 'Whatsapp' ) {
      this.qtdeCaracteresRestantes = 1000;
      this.QTDE_MAXIMA_CARACTERES = 1000;
    //}
  }

  atualizeAltura() {
//    this.resize(this.txtNovaIdeia.nativeElement);
  }
}
