import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends  ServerService {
  constructor(public http: HttpClient) {
      super(http)
  }

  atualizeEnviarLinksBotao(empresa: any) {
    return this.http.put('/empresas/atualizeEnviarLinksBotao', {
      id: empresa.id,
      enviarLinksBotao: empresa.enviarLinksBotao
    })
      .toPromise();
  }

  atualizeBotAtivo(empresa: any) {
    return this.http.put('/empresas/atualizeBotAtivo', {
      id: empresa.id,
      dominio: empresa.dominio,
      cardapio: empresa.cardapio
    })
      .toPromise();
  }

  salveNovaLocalizacao(empresa: any, latitude: string, longitude: string) {
    return this.http.put('/empresas/atualizeLocalizacao', {
      id: empresa.id,
      latitude: latitude,
      longitude:  longitude
    })
      .toPromise();
  }

  altereAceitarPedido(aceitarAutomatico: boolean){
    return this.facaPut('/empresas/pedidos/aceitar/auto', {aceitarAutomatico: aceitarAutomatico})
  }

  salveIntegracaoIfood(empresa: any, integracao: any){
    return this.facaPost('/ifood/' + empresa.id + '/integracao/ifood', integracao);
  }

  removaIntegracaoIfood(integracaoIfood: any){
    return this.facaPost('/ifood/integracao/ifood/remova/' + integracaoIfood.id, {});
  }

  desativeIntegracaoIfood(integracaoIfood: any){
    return this.facaPost('/ifood/integracao/ifood/desative/' + integracaoIfood.id, {});
  }

  ativeIntegracaoIfood(integracaoIfood: any){
    return this.facaPost('/ifood/integracao/ifood/ative/' + integracaoIfood.id, {});
  }


  atualizeIntegracaoIfood(integracao: any){
    return this.facaPost('/ifood/integracao/atualize', integracao);
  }

  autorizeNovaLoja(dados: any){
    return this.facaPost('/ifood/autorizacao/confirme', dados);
  }
  gereLinkAtivacaoIfood(){
    return this.facaPost('/ifood/autorizacao/gere', {});
  }
  sincronizeFormasPagamentoIfood(){
    return this.facaPost('/ifood/formaspagamento/sincronize', {});
  }

  obtenhaAtivacaoPendente(empresa: any){
    return this.http.get('/ifood/autorizacao/' + empresa.id , { })
      .toPromise().then(this.retorno).catch(this.handleError);

  }


  listeFormasAtivasMesa(){
    return this.http.get('/integracaoerp/formaspagamento/mesas/ativas' , { })
      .toPromise().then(this.retorno).catch(this.handleError);

  }
}
