import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';

import { PainelRedeRoutingModule } from './painel-rede-routing.module';
import { PainelRedeComponent } from './painel-rede/painel-rede.component';
import { DashboardRedeComponent } from './dashboard-rede/dashboard-rede.component';
import {MenuRedeComponent} from "../menu-rede/menu-rede.component";
import {LojasRedeComponent} from "../lojas-rede/lojas-rede.component";
import {ExcelModule, GridModule} from "@progress/kendo-angular-grid";
import {TooltipModule} from "@progress/kendo-angular-tooltip";
import {FormsModule} from "@angular/forms";
import {FinanceiroDeliveryComponent} from "../../fidelidade/financeiro-delivery/financeiro-delivery.component";
import {ApplicationPipesModule} from "../../pipes/application-pipes.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {IntlModule} from "@progress/kendo-angular-intl";
import {CompartilhadoModule} from "../../compartilhado/compartilhado.module";
import {FidelidadeRoutingModule} from "../../fidelidade/fidelidade.routing";
import {NotificationModule} from "@progress/kendo-angular-notification";
import {InlineSVGModule} from "ng-inline-svg";
import {ChartsModule} from "@progress/kendo-angular-charts";
import {NgxMaskModule} from "ngx-mask";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {DateInputsModule} from "@progress/kendo-angular-dateinputs";
import {MaskedTextBoxModule, NumericTextBoxModule, SwitchModule} from "@progress/kendo-angular-inputs";
import {DialogModule, WindowModule} from "@progress/kendo-angular-dialog";
import {ButtonModule, DropDownButtonModule} from "@progress/kendo-angular-buttons";
import {ChatModule} from "@progress/kendo-angular-conversational-ui";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {NovaCampanhaRedeComponent} from "../marketing-rede/nova-campanha-rede/nova-campanha-rede.component";
import {UploadModule} from "@progress/kendo-angular-upload";
import {ExibirCampanhaRedeComponent} from "../marketing-rede/exibir-campanha/exibir-campanha-rede.component";
import {MarketingRedeComponent} from "../marketing-rede/marketing-rede.component";
import {TelaProdutosCatalogoComponent} from "../../superadmin/tela-produtos-catalogo/tela-produtos-catalogo.component";
import {ListaEmpresasCampanhaRedeComponent} from "../marketing-rede/lista-empresas/lista-empresas-campanha-rede.component";
import {LabelModule} from "@progress/kendo-angular-label";
import {PerformanceWhatsappComponent} from "../../fidelidade/performance-whatsapp/performance-whatsapp.component";
import {RelatoriosComponent} from "../../relatorios/relatorios.component";
import {TelaGraficoFidelidadeComponent} from "../../tela-grafico-fidelidade/tela-grafico-fidelidade.component";
import {
  TelaContatosPorCategoriaComponent
} from "../../tela-contatos-por-categoria/tela-contatos-por-categoria.component";
import {TelaGraficoComparacaoComponent} from "../../tela-grafico-comparacao/tela-grafico-comparacao.component";
import {FormTemplateComponent} from "../../compartilhado/form-template/form-template.component";
import {TabStripModule} from "@progress/kendo-angular-layout";
import {FidelidadeModule} from "../../fidelidade/fidelidade.module";

@NgModule({
  declarations: [
    PainelRedeComponent,
    DashboardRedeComponent,
    MenuRedeComponent,
    MarketingRedeComponent,
    ExibirCampanhaRedeComponent,
    NovaCampanhaRedeComponent,
    LojasRedeComponent,
    TelaProdutosCatalogoComponent,
    ListaEmpresasCampanhaRedeComponent,
    PerformanceWhatsappComponent,

    RelatoriosComponent,
    TelaGraficoFidelidadeComponent,
    TelaContatosPorCategoriaComponent,
    TelaGraficoComparacaoComponent
  ],
    imports: [
        CommonModule,
        ApplicationPipesModule,
        InfiniteScrollModule,
        FormsModule,
        IntlModule,
        CompartilhadoModule,
        PainelRedeRoutingModule,
        InlineSVGModule, ChartsModule,
        NgxMaskModule, DropDownsModule, DateInputsModule,
        SwitchModule, WindowModule, ButtonModule, DialogModule, ChatModule,
        GridModule, ProgressBarModule, NumericTextBoxModule, MaskedTextBoxModule, TooltipModule, ExcelModule, UploadModule, LabelModule, TabStripModule, FidelidadeModule, DropDownButtonModule
    ],
  providers: [
    CurrencyPipe,
    MonitoradorPedidos
  ]
})
export class PainelRedeModule { }
