import {Categoria} from "./delivery/Categoria";
import {AdicionalDeProduto} from "./delivery/AdicionalDeProduto";
import {AdicionalDeProdutoEscolhaSimples} from "./delivery/AdicionalDeProdutoEscolhaSimples";
import {AdicionalDeProdutoMultiplaEscolha} from "./delivery/AdicionalDeProdutoMultiplaEscolha";
import {UnidadeMedida} from "./UnidadeMedida";
import {EnumTipoDeVenda} from "../lib/emun/EnumTipoDeVenda";
import {EnumDisponibilidadeProduto} from "../lib/emun/EnumDisponibilidadeProduto";
import {ProdutoTamanho} from "./templates/ProdutoTamanho";
import {ImagemDoProduto} from "./ImagemDoProduto";
import {ImagemEan} from "./ImagemEan";
import {OpcaoDeAdicionalDeProduto} from "./delivery/OpcaoDeAdicionalDeProduto";
import {ProdutoTemplate} from "./templates/ProdutoTemplate";
import * as _ from "underscore";
import {EnumTipoDeOrigem} from "../lib/emun/EnumTipoDeOrigem";
import {Catalogo} from "./catalogo/Catalogo";
import {ProdutoNaEmpresa} from "./catalogo/ProdutoNaEmpresa";
import {TagProduto} from "./TagProduto";


export class Produto {
  public static  diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

  dummy1: AdicionalDeProdutoEscolhaSimples;
  dummy2: AdicionalDeProdutoMultiplaEscolha
  ordem: number;
  qtdMaxima: number;
  qtdeMinima = 1;
  pesoMinimo: number;
  pesoMaximo: number;
  camposAdicionais: Array<AdicionalDeProduto> = [];
  temEstoque: boolean
  disponibilidades: any = [];
  tipo: any = 'normal'
  novoPreco: number;
  percentualDesconto: number;
  destaque: boolean;
  codigoPdv: string;
  idIfood: string;
  codigoDeBarras: string;
  imagemCodigoDeBarras: ImagemEan;
  naoAceitaCupom = false;
  naoSincronizar = false;
  ehBrinde = false;
  categoriasAcima: Array<Categoria> = [];
  origem: EnumTipoDeOrigem;
  dataCadastro: Date;
  produtoOriginal: Produto;
  produtoNaEmpresa: ProdutoNaEmpresa
  pontosGanhos: number;
  cashback: number;
  tamanhos: ProdutoTamanho[];
  sincronizarModelos = false;
  tags: Array<TagProduto> = [];
  constructor(public  id: number= null, public nome: string = null, public preco: number= null,
              public descricao: string = '', public mensagemPedido: string = null, public imagens: ImagemDoProduto[]= null ,
              public catalogo: Catalogo = null, public exibirNoSite: boolean = false,
              public disponibilidade: any = null,
              public exibirPrecoSite = false, public categoria: Categoria = null,
              public tipoDeVenda: EnumTipoDeVenda = null, public unidadeMedida: UnidadeMedida = null,
              public valorInicial: number = null,  public incremento: number = null,
              public disponivelParaDelivery: boolean = true, public disponivelNaMesa: boolean = true,
              public exibirPrecoNoCardapio = true , public sku: string = null) {

    if(!tipoDeVenda || tipoDeVenda !== EnumTipoDeVenda.Peso){
      this.unidadeMedida = null
      if(!this.incremento) this.incremento = 1;
      this.valorInicial = null;
    }
    this.temEstoque = true;
  }

  setDadosTela(dados: any, empresa: any){
    if(!this.catalogo) this.catalogo = empresa.catalogo;

    if(!this.id){
      this.origem = EnumTipoDeOrigem.Cadastrado;
      this.dataCadastro = new Date();

      if(dados.catalogo.precoPorEmpresa && (empresa.catalogo.id === dados.catalogo.id) && dados.precoNaEmpresa) {
        this.preco = dados.precoNaEmpresa
        this.novoPreco = dados.novoPrecoNaEmpresa
        this.destaque = dados.destaqueNaEmpresa
      } //quando o produto é criado numa empresa, o preço vai sempre para todos os produtos
    } else {
      if(dados.catalogo.precoPorEmpresa && (this.catalogo.id === dados.catalogo.id) && dados.precoNaEmpresa) {
        this.produtoNaEmpresa = new ProdutoNaEmpresa(this, empresa, dados.precoNaEmpresa)
        this.produtoNaEmpresa.novoPreco = dados.novoPrecoNaEmpresa
        this.produtoNaEmpresa.destaque = dados.destaqueNaEmpresa
      }
    }

    this.ehBrinde = dados.ehBrinde;
    this.qtdeMinima = dados.qtdeMinima;
    this.qtdMaxima = dados.qtdMaxima;
    this.pesoMinimo = dados.pesoMinimo;
    this.pesoMaximo = dados.pesoMaximo;
    this.destaque = dados.destaque;
    this.naoAceitaCupom = dados.naoAceitaCupom;
    this.codigoPdv = dados.codigoPdv;
    this.sku = dados.sku;
    this.percentualDesconto = dados.percentualDesconto;
    this.novoPreco = dados.novoPreco;

    if(dados.sincronizarModelos) this.sincronizarModelos = true;


  }


  obtenhaPrecoUnidade(peso: number, tamanho: any,  sabores: Array<any>) {
    if(this.vendaPorPeso() )
      return this.obtenhaPrecoPorPeso(peso)

    return this.obtenhaPreco(tamanho, sabores) ;
  }

  obtenhaPrecoTotal(qtde: number, tamanho: ProdutoTamanho, sabores: Array<any>) {
    let preco = this.obtenhaPrecoUnidade(qtde, tamanho, sabores);

    if(this.vendaPorPeso() )
      return preco;

    return Number((preco * qtde ).toFixed(2));
  }

  setPrecoPromocional(novoPreco: number){
    this.novoPreco = novoPreco;
    this.percentualDesconto =  Number((  100 - ( this.novoPreco / this.preco ) * 100 ).toFixed(2));
  }

  obtenhaPrecos(){
    return { preco: this.preco, novoPreco: this.novoPreco}
  }

  obtenhaPreco(tamanho: any, sabores: Array<any>){
    if(tamanho)
      return tamanho.obtenhaPreco()

    if(this.novoPreco) return this.novoPreco;

    return   this.preco;
  }

  private obtenhaPrecoPorPeso(peso: number): number{
    if(this.unidadeMedida && this.unidadeMedida.sigla === 'g'  )
      peso = peso / 1000;

    let precoUnidade = this.novoPreco ? this.novoPreco : this.preco;

    return Number((precoUnidade * peso ).toFixed(2)) ;
  }

  verifiqueSeBooleanoEstaPreenchido(variavel: any) {
    return variavel === true || variavel === false;


  }

  processeParaLoja(){
    this.setValoresDeVenda();

    this.camposAdicionais.forEach((adicional: any) => {
      adicional.opcoesDisponiveis = adicional.opcoesDisponiveis ?
        adicional.opcoesDisponiveis.filter((opcao: OpcaoDeAdicionalDeProduto) => {
          opcao.adicional = {id: adicional.id} as AdicionalDeProduto

          if(this.catalogo.precoPorEmpresa && opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.id)
            opcao.valor = opcao.opcaoNaEmpresa.valor;

          if(this.catalogo.disponibilidadePorEmpresa ){
            if(opcao.opcaoNaEmpresa) {
              if((opcao.opcaoNaEmpresa.id || opcao.opcaoNaEmpresa.novo)
                && this.verifiqueSeBooleanoEstaPreenchido(opcao.opcaoNaEmpresa.disponivel)){
                //todo forçar disponivel na opçao para corrigir um bug na loja
                if(!opcao.disponivel){
                  if(opcao.opcaoNaEmpresa && opcao.opcaoNaEmpresa.disponivel)
                    opcao.disponivel = true;
                }
                return opcao.opcaoNaEmpresa.disponivel
              }
            }
          }

          return opcao.estaDisponivel()
        } ) : [];

      if(adicional.campoOrdenar)
        adicional.opcoesDisponiveis = _.sortBy( adicional.opcoesDisponiveis, (opcao: any) => opcao[adicional.campoOrdenar])


    });

    this.camposAdicionais = this.camposAdicionais.filter((adicional: AdicionalDeProduto) => {
      return adicional.obrigatorio ||  adicional.opcoesDisponiveis.length > 0
    }) // mantém como campo adicional os obrigatórios ou não obrigatórios que não estejam vazios

    let produto = this;

    produto.carreguePrecoNaEmpresa();


    (produto as any).menorPreco = this.obtenhaValorMinimo();

    if (!this.categoria)
      (this.categoria as any)   = {
        id: -1,
        nome: 'Outros',
        disponivel: true,
        posicao: 1000
      };

    //delete this.empresa;
  }

  carreguePrecoNaEmpresa() {
    let produto: any = this;

    if(this.catalogo && this.catalogo.precoPorEmpresa &&
      produto.produtoNaEmpresa && produto.produtoNaEmpresa.preco) {


      if(!produto.novoPreco)
        produto.preco = produto.produtoNaEmpresa.preco;
      else
        produto.precoAntigo = produto.produtoNaEmpresa.preco;

      if(produto.produtoNaEmpresa.novoPreco){
        produto.precoAntigo = produto.produtoNaEmpresa.preco;
        produto.preco = produto.produtoNaEmpresa.novoPreco;
        produto.novoPreco = produto.produtoNaEmpresa.novoPreco;

        produto.desconto =  produto.preco -  produto.precoAntigo;
        produto.destaque = produto.produtoNaEmpresa.destaque;
      }
    }

  }

  private obtenhaMinimoOpcoes(opcoesDisponiveis: any, tipoDeCobranca: string, qtdMinima: number ){
    let opcoesOrdenadas =  _.sortBy(opcoesDisponiveis, (opcao: any) => opcao.valor);

    opcoesOrdenadas = opcoesOrdenadas.filter((opcao: any) => this.opcaoEstaDisponivel(opcao))

    if (tipoDeCobranca === 'MEDIA') {
      let qtde = qtdMinima || 1;

      let somaMenores = opcoesOrdenadas.slice(0, qtde).reduce((soma: any, opcao: any) => soma + opcao.valor, 0);

       return somaMenores / qtde;

    } else {
      let maisBarato = opcoesOrdenadas[0];

      if( maisBarato ){
        if(tipoDeCobranca === 'MAIOR'){
          return maisBarato.valor;
        } else {
          return  ( qtdMinima || 1) * maisBarato.valor;
        }
      }
    }
  }


  obtenhaValorMinimo(): number {
    let valorMinimo = this.preco

    if(this.camposAdicionais && this.camposAdicionais.length > 0) {
      let minimoAdicional = this.preco

      let adicionaisObrigatorios = this.camposAdicionais.filter((item: any) =>
        item.obrigatorio && item.opcoesDisponiveis && item.opcoesDisponiveis.length)

      let adicionaisSemDependencia: Array<any> =
        adicionaisObrigatorios.filter((item: any) => item.opcoesDisponiveis.find((opcao: any) => !opcao.dependencias.length));

      let adicionaisComDependencia: Array<any>  =
        adicionaisObrigatorios.filter((item: any) => item.opcoesDisponiveis.find((opcao: any) => opcao.dependencias.length));

      for(let campoAdicional of adicionaisSemDependencia)
        minimoAdicional += this.obtenhaMinimoOpcoes(campoAdicional.opcoesDisponiveis, campoAdicional.tipoDeCobranca,
            campoAdicional.qtdMinima)

      //todo: esse calculo para contemptar todos casos vai ter feito na tela baseado na escolha do usuario e entre adicionais visiveis
      if(adicionaisComDependencia.length){
        let menoresEntreDependentes: any = {};

        for(let campoAdicional of adicionaisComDependencia){
          let opcaoComDependencia = campoAdicional.opcoesDisponiveis.find((opcao: any) => opcao.dependencias.length);

          let idAdicional: any = opcaoComDependencia ?  opcaoComDependencia.dependencias[0].adicionalId : 0;

          let menorValor = this.obtenhaMinimoOpcoes(campoAdicional.opcoesDisponiveis, campoAdicional.tipoDeCobranca,
            campoAdicional.qtdMinima);

          if(!menoresEntreDependentes[idAdicional] )
            menoresEntreDependentes[idAdicional] = []

          menoresEntreDependentes[idAdicional].push(menorValor);
        }

        Object.keys(menoresEntreDependentes).forEach((idAdicional: any) => {
          let menorValorDependentes: any =  _.sortBy(menoresEntreDependentes[idAdicional], (valor) => valor)[0];

          minimoAdicional += menorValorDependentes as number;
        })

      }


      if(minimoAdicional > valorMinimo &&  !this.exibirUnidade())
        valorMinimo = minimoAdicional

    }

    return  valorMinimo ? Number(valorMinimo.toFixed(2)) : valorMinimo;

  }



  exibirUnidade() {
    return this.vendaPorPeso()
  }

  vendaPorPeso() {
    return this.tipoDeVenda && this.tipoDeVenda === EnumTipoDeVenda.Peso;
  }

  estaDisponivelHojeNoHorario(empresa: any, catalogo: any) {
     // mantendo comaptibiidade com modelo anterior: se nao tem nenhuma disponiblidade configurada padrao é nao estar disponivel
     let resposta = this.disponibilidades.length <= 0;

     this.disponibilidades.forEach((disp: any) => {
       let disponibilidade =  catalogo.disponibilidades.find((item: any) => item.id === disp.id)

       if(!disponibilidade){
         console.error(String(`Disponibilidade do produto ${this.id} não esta no catalogo: ${catalogo.id} `))
       }

        if(disponibilidade && disponibilidade.estaDisponivelAgora(empresa.fusoHorario))
          resposta = true;
     })


     return resposta;
  }

  estaDisponivelHoje(empresa: any, catalogo: any) {
    if(this.disponibilidade !== EnumDisponibilidadeProduto.DiaDaSemana) return  true;

    return this.estaDisponivelHojeNoHorario(empresa, catalogo)

  }

  temTamanho() {
      return false;
  }

  adicionaisObrigatoriosEstaoDisponiveis() {
    if(!this.camposAdicionais || this.camposAdicionais.length === 0) return true

    for(let adicional of this.camposAdicionais)
      if(adicional.obrigatorio && adicional.opcoesDisponiveis.length === 0) return false


    return true;
  }

  setValoresDeVenda(){
    let produto: any = this;

    if(produto.novoPreco){
      produto.precoAntigo = produto.preco;
      produto.preco = produto.novoPreco;
      produto.desconto =  produto.preco -  produto.precoAntigo;
    }

    if (produto.tamanhos && produto.tamanhos.length){
      produto.tamanhos = produto.tamanhos.filter((tamanho: ProdutoTamanho) => tamanho.estaDisponivel() && tamanho.preco > 0)
      produto.precoAntigo = produto.obtenhaValorMinimoAntigo();

      produto.tamanhos.forEach((tamanho: any) => {
         tamanho.setValoresDeVenda()
      })

    }

    let valorMinimo = produto.obtenhaValorMinimo();

    if (valorMinimo !== produto.preco)
      produto.valorMinimo = valorMinimo;
  }



  estaDisponivelHojeNaEmpresa(empresa: any, catalogo: any) {
    if(this.produtoNaEmpresa.disponibilidade !== EnumDisponibilidadeProduto.DiaDaSemana)
      return true

    return this.estaDisponivelHojeNoHorario(empresa, catalogo)
  }

  estaDisponivelNaEmpresa(empresa: any, catalogo: any) {
    return [EnumDisponibilidadeProduto.SempreDisponivel,
      EnumDisponibilidadeProduto.DiaDaSemana].indexOf(this.produtoNaEmpresa.disponibilidade) >= 0 &&
      this.estaDisponivelHojeNaEmpresa(empresa, catalogo)
  }

  estaDisponivel(empresa: any, catalogo: any = null) {
    if(this.categoria && !this.categoria.disponivel) return  false;

    let disponibilidadePorEmpresa = catalogo ? catalogo.disponibilidadePorEmpresa : false;

    if((disponibilidadePorEmpresa && this.produtoNaEmpresa) && this.produtoNaEmpresa.disponibilidade != null)
      return this.estaDisponivelNaEmpresa(empresa, catalogo)

    return this.temEstoque && this.estaDisponivelHoje(empresa, catalogo);
  }

  exibirCardapioImpresso() {
    if(this.categoria && !this.categoria.disponivel) return  false;

    return Number(this.disponibilidade) !== EnumDisponibilidadeProduto.NaoDisponivel;
  }

  obtenhaNomeCompleto(){
    return this.nome;
  }

  protected crieNovo() {
    return new Produto(null, this.nome, this.preco, this.descricao, this.mensagemPedido, [], this.catalogo,
      this.exibirNoSite, this.disponibilidade, this.exibirPrecoSite, this.categoria, this.tipoDeVenda, this.unidadeMedida,
      this.valorInicial, this.incremento, this.disponivelParaDelivery, this.disponivelNaMesa, this.exibirPrecoNoCardapio,
      this.sku)

  }

  clone() {
    let produto = this.crieNovo()

    produto.novoPreco = this.novoPreco;
    produto.percentualDesconto = this.percentualDesconto;
    produto.disponibilidades = this.disponibilidades;

    if(this.imagens)
      for(let imagem of this.imagens) {
        let cloneImagem = new ImagemDoProduto(imagem.linkImagem, imagem.ordem)

        produto.imagens.push(cloneImagem)
      }
    produto.temEstoque = this.temEstoque;
    produto.camposAdicionais = []
    produto.codigoPdv = this.codigoPdv
    let  i = 0;
    if(this.camposAdicionais)
      for(let campoAdicional of this.camposAdicionais) {
        let cloneCampoAdicional: any = {}

        Object.assign(cloneCampoAdicional, campoAdicional)

        cloneCampoAdicional.id = null
        cloneCampoAdicional.ordem = i++;
        cloneCampoAdicional.opcoesDisponiveis = campoAdicional.opcoesDisponiveis.map((opcao) => {
          let cloneOpcao: OpcaoDeAdicionalDeProduto = {} as OpcaoDeAdicionalDeProduto

          Object.assign(cloneOpcao, opcao)

          cloneOpcao.id = null
          return cloneOpcao
        })

        produto.camposAdicionais.push(cloneCampoAdicional)
      }

    return produto
  }

  //como nao temos multiplas categorias, mesmo produto em categoiras diferentes sao duplicados
  ehMesmoNaCategoria(produtoChina: any){
    let codigosDiferentes = produtoChina.codigoPdv !== this.codigoPdv;

    if(codigosDiferentes) return false;

    let mesmoNome = this.nome.toUpperCase() === produtoChina.nome.toUpperCase();

    if(!this.categoria || !this.codigoPdv)
      return  mesmoNome;

    let codigoGenerico =  this.codigoPdv.startsWith('99999') || this.codigoPdv.startsWith('VAZIO')
    let mesmaCategoria =   this.categoria.ehIgual(produtoChina.categoria);

    if(codigoGenerico) return mesmoNome && mesmaCategoria

    //mesmo produto existe no site em diferentes  categorias, aqui nao tempos suporte a varias categorias
    return mesmaCategoria

  }

  public obtenhaCampoAdicional(template: ProdutoTemplate): AdicionalDeProduto {
    if( !template ) return null;

    for( let adicional of this.camposAdicionais ) {
      if( adicional === null ) {
        continue;
      }
      if( adicional.template && adicional.template.id === template.id ) {
        return adicional;
      }
    }

    return null;
  }

  setAdicionais(camposAdicionais: any){
    this.camposAdicionais = []
    camposAdicionais.forEach((camposAdicional: any) => {
      delete  camposAdicional.produtos;
      this.camposAdicionais.push(camposAdicional)
    })
  }

  public temMassaSemBorda(){
    return false;
  }

  private opcaoEstaDisponivel(opcao: OpcaoDeAdicionalDeProduto) {
    if(!this.catalogo ||
      !this.catalogo.disponibilidadePorEmpresa || !opcao.opcaoNaEmpresa || !opcao.opcaoNaEmpresa.id)
      return opcao.disponivel

    return opcao.opcaoNaEmpresa.disponivel;
  }

  obtenhaDescricaoPromocao() {
    const produto: any = this;
    let descricao = `${this.nome}`;

    if (this.destaque) {
      descricao +=
` de ~${produto.precoAntigo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}~ por *${produto.novoPreco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}*`;
    }

    if (produto.tipo === 'pizza') {
      descricao = 'Pizza ' + descricao;

      produto.tamanhos.forEach((tamanho: any) => {
        if (tamanho.destaque && tamanho.precoAntigo && tamanho.novoPreco) {
          descricao += ` (${tamanho.descricao} de ~${tamanho.precoAntigo?.toLocaleString('pt-BR',
            { style: 'currency', currency: 'BRL' })}~ por *${tamanho.novoPreco?.toLocaleString('pt-BR',
            { style: 'currency', currency: 'BRL' })}*) `;
        }
      });
    }

    return descricao;
  }

}

