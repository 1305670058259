import {AfterViewInit, Component, OnInit} from '@angular/core';

import {NotificacoesService} from "../../services/notificacoes.service";
import {TipoDeNotificacaoEnum} from "../../../../server/domain/TipoDeNotificacaoEnum";
import {ConstantsService} from "../ConstantsService";
import {data} from "cheerio/lib/api/attributes";
import { EmpresaService } from '../../services/empresa.service';

@Component({
  selector: 'app-noticacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.css']
})
export class NotificacoesComponent implements OnInit, AfterViewInit {
  contato = {};
  notificacoesFidelidade = [];
  notificacoesPedidos = [];
  notificacoesSDR = [];
  notificacoesEntregas = [];

  loading = false;
  temModuloPedidos
  temModuloFidelidade
  empresa: any;
mensagemSucessoEnviarLinksBotao: any;

  constructor(private notificacoesService: NotificacoesService, private constantsService: ConstantsService, private empresaService: EmpresaService) {
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.loading = true;
    this.constantsService.empresa$.subscribe( data => this.empresa = data);

    this.constantsService.moduloPedido$.subscribe( data => this.temModuloPedidos = data)
    this.constantsService.moduloFidelidade$.subscribe( data => this.temModuloFidelidade = data)

    this.notificacoesService.todas().then( (notificacoes)  => {

      this.loading = false;

      this.notificacoesEntregas = this.filtreRemovendo(notificacoes,
        notificacao => notificacao.tipoDeNotificacao.indexOf('Entregador') >= 0)


      this.filtreRemovendo(notificacoes,  notificacao => notificacao.tipoDeNotificacao === 'Carrinho Abandonado');

      this.notificacoesPedidos = this.filtreRemovendo(notificacoes,  notificacao => notificacao.tipoDeNotificacao.indexOf('Pedido') >= 0 ||
        notificacao.tipoDeNotificacao.indexOf('Comanda') >= 0);



      this.notificacoesSDR = this.filtreRemovendo(notificacoes,  notificacao => notificacao.tipoDeNotificacao.indexOf('Etapa ') >= 0);


      this.notificacoesFidelidade = notificacoes
    });
  }

  filtreRemovendo(lista: [], funcaoFiltro) {
    let listaFiltrada = lista.filter(funcaoFiltro);

    listaFiltrada.forEach(function(element) {
      let  index = lista.indexOf(element)
      lista.splice(index, 1)
    })

    return listaFiltrada
  }

  onEnviarLinksBotaoChange() {
    this.mensagemSucessoEnviarLinksBotao = null;

    this.empresaService.atualizeEnviarLinksBotao(this.empresa).then( () => {
      this.mensagemSucessoEnviarLinksBotao = 'Configuração atualizada com sucesso. ' + (this.empresa.enviarLinksBotao ? 'Links serão enviados como botão no Whatsapp.' :
        'As mensagens serão enviadas como texto sem botão.');
    });
  }
}
